@font-face {
    font-family: 'CascadiaCode';
    src: url('./Assetts/CascadiaCode.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

.screen-container{
    /* background-color: red; */
    display: flex;
    width: 100%;
    position: relative;
    overflow: hidden;
    font-family: 'CascaidaCode', monospace;
}
.screen-content-container{
    background-color: #1e1e1e;
    display: flex;
    width: 100%;
    color: white;
}
.screen-home-content{
    background-color: #1e1e1e;
    display: flex;
    width: 100%;
}
 
.screen-other-content{
    background-color: #1e1e1e;
    width: 100%;
    /* max-width: 50vw; */
    display: flex;
    flex-direction: row;
}

.screen-about-content{
    background-color: #1e1e1e;
    display: flex;
    width: 100%;
}

@media screen and (max-width: 750px) {
    .screen-other-content{
        flex-direction: column;
    }
}

.screen-other-left{
    background-color: #1e1e1e;
    flex: 1;
    /* max-width: 70%; */
    overflow-y: auto;
    /* height: 100%; */
}

.screen-other-right{
    background-color: #1e1e1e;
    flex: 1;
    overflow: hidden;
    /* height: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.screen-query-container{
    width: 100%;
    /* position: fixed; */
    position: absolute;
    /* display: none; */
    background-color: black;
    color: white;
    bottom: 0;
    z-index: 1000;
} 

