.leftbar-container{
    display: flex;
    flex-direction: row;
    background-color: #333333;
    width: 100%;
}

.left-icon-container{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 40%;
    margin-top: 15%;
}
.left-icon-container > div {
    width: 100%;
    height:40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.left-icon-container > div:hover{
    cursor: pointer;
    border-left: 2px solid white;
}

.resume-icon-cont > a{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebar-container{
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 2;
    /* background-color: beige; */
}