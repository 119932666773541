.landing-container{
    /* background-color: red; */
    display: flex;
    max-width: 90vw;
    flex-direction: column;
    padding: 20px; 
}

.landing-line{
    display: flex;
}

.line-numbers{
    /* display: flex; */
    min-width: 35px;
    /* text-align: center; */
    /* position: absolute; */
    /* left: 0;
    top: 0; */
    /* padding-left: 15px; */
    /* background-color: blue; */
    color: white;
    text-align: left;
    user-select: none;
    border-right: 2px solid;
}

.line-text{
    padding-left: 35px;
    color: #C3E88D;
}