*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App{
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.content-container{
  /* background-color: red; */
  height: 100%;
  display: flex;
  flex-direction: row;
}

.left-container{
  display: flex;
  flex: 1;
  /* background-color: yellow; */
}
.right-container{
  display: flex;
  flex: 5;
  /* background-color: blue; */
}