.carousel-container {
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    user-select: none;
}

.image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* width: 100%;
    height: 100%; */
    color: white;
}

.image-container> img {
    width: 75%;
    height: 75%;
    /* object-fit: contain; */
}

@media screen and (max-width: 750px) {
    .image-container> img {
        max-height: 250px;
        max-width: 300px;
    }
}

.btn-container {
    margin-top: 15px;
    /* background-color: green; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.btn-container> img {
    cursor: pointer;
}