.code-editor {
    display: flex;
    /* height: 100%; */
    /* max-height: 100%; */
    color: white;
    margin-top: 20px;
    /* padding-left: 20px; */
    position: relative;
    overflow: hidden;
    max-width: 90vw;
    white-space: pre-wrap;
    word-wrap: break-word;
    /* height: 100%; */
    background-color: #1e1e1e;
    margin-bottom: 30px;
}

.code-content {
    padding-left: 20px; 
    padding-right: 20px;
    /* background-color: red; */
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.line{
    display: flex;
    align-items: center;
}
.line-number {
    display: flex;
    min-width: 35px;
    /* text-align: center; */
    /* position: absolute; */
    /* left: 0;
    top: 0; */
    /* padding-left: 15px; */
    /* background-color: blue; */
    color: white;
    text-align: left;
    user-select: none;
    border-right: 2px solid;
}

.line>span {
    display: flex;
    /* border-bottom: 2px solid black; */
}
.line-content{
    /* display: block; */
    /* background-color: green; */
    padding-left: 20px;
}

a:link{
    color:  #ffa366;
}

a:visited{
    color: #ff751a;
}